window.angular.module('MyHippoProducer').directive('leadPolicyFilters', function () {
    'ngInject';
    return {
        restrict: 'E',
        scope: {
            type: '@',
            results: '=',
            totalItems: '=',
            filters: '=',
            hasCommunityColumns: '=',
            currentPage: '<',
            itemsPerPage: '<',
            isLoading: '=',
            defaultFilter: '<',
            hasFetchedResults: '=',
        },
        replace: true,
        template: `
            <form>
                <div class="container-filters">
                    <div ng-if="showCreatedByFilter">
                        <label class="label">Created by</label>
                        <md-select ng-model="filters.scope" placeholder="Created by">
                            <md-option ng-value="'producer'">Me</md-option>
                            <md-option ng-value="'organization'">Agency</md-option>
                        </md-select>
                    </div>
                    <div class="fix-width-field">
                        <label class="label">Organization</label>
                        <md-select ng-model="filters.organization" aria-label="Organization Filter">
                            <md-option ng-value="'all'">All</md-option>
                            <md-option ng-repeat="filterOrg in filterOrganizations" ng-value="{{filterOrg.id}}">{{filterOrg.name}}</md-option>
                        </md-select>
                    </div>
                    <div ng-if="type !== 'leads'" class="fix-width-field filter-status">
                        <label class="label">Status</label>
                        <md-select ng-model="filters.status" placeholder="All">
                            <md-option ng-value="'all'">All</md-option>
                            <md-option ng-value="'pending_active'">Pending Active</md-option>
                            <md-option ng-value="'active'">Active</md-option>
                            <md-option ng-value="'renewal_offered'">Renewal Offered</md-option>
                            <md-option ng-value="'pending_non_renewal'">Pending Non-Renewal</md-option>
                            <md-option ng-value="'pending_cancellation'">Pending Cancellation</md-option>
                            <md-option ng-value="'terminated'">Terminated</md-option>
                            <md-option ng-value="'expired'">Expired</md-option>
                        </md-select>
                    </div>
                    <div ng-if="type !== 'leads'">
                        <label class="label">Effective date range</label>
                        <div class="datepicker">
                            <md-datepicker ng-model="filters.effectiveDateAfter" md-placeholder="Start"></md-datepicker>
                            <div class="datepicker-divider">to</div>
                            <md-datepicker ng-model="filters.effectiveDateBefore" md-placeholder="End"></md-datepicker>
                        </div>
                    </div>
                    <div class="fix-width-field">
                        <label class="label">Created date</label>
                        <md-select ng-model="filters.timeRange" placeholder="Time Range">
                            <md-option ng-value="'month_to_date'">Month to date</md-option>
                            <md-option ng-value="'last_30_days'">Last 30 days</md-option>
                            <md-option ng-value="'quarter_to_date'">Quarter to date</md-option>
                            <md-option ng-value="'year_to_date'">Year to date</md-option>
                            <md-option ng-value="'last_12_months'">Last 12 months</md-option>
                            <md-option ng-value="'last_year'">Last year</md-option>
                            <md-option ng-value="''">Since the beginning of time</md-option>
                        </md-select>
                    </div>
                    <div class="search-by-keyword">
                        <label class="label">Search by keyword</label>
                        <tooltip text="'Search by customer name, policy number or address.'"></tooltip>
                        <md-input-container md-no-float>
                            <input type="search" ng-model="filters.keyword" ng-model-options="{debounce: 200}" placeholder="Try a name, policy or address..." autofocusafterclear />
                            <span class="clear-search" ng-if="filters.keyword.length > 0" ng-click="clearKeyWord()">X</span>
                        </md-input-container>
                    </div>
                    <div class="search-button">
                        <hc-button type="secondary" ng-click="policiesSearch()">Apply</hc-button>
                    </div>
                    <div class="clear-button">
                        <button ng-click="clearSearch()" ng-if="searchBarFilled">Clear</button>
                    </div>
                    <div class="filter-rating" ng-if="shouldUseSegmentation">
                        <div class='filter-rating-label'>Show rating</div>
                        <toggle is-toggled="false" ng-click="filterRating()"></toggle>
                    </div>
                </div>
            </form>
        `,
        controller: function ($log, $scope, $rootScope, $state, $mdDialog, UserService, APIService, spinnerService, IncentivesService, FlagshipService) {
            'ngInject';
            const {
                FLAGSHIP_SEGMENTATION_CAMPAIGN_ID
            } = window.appConfig;
            $scope.showCreatedByFilter = UserService.canViewCreatedByFilter();

            $scope.currentPage = angular.isDefined($scope.currentPage) ? $scope.currentPage : 1;
            $scope.itemsPerPage = angular.isDefined($scope.itemsPerPage) ? $scope.itemsPerPage : 100;
            $scope.filterOrganizations = [];
            $scope.searchBarFilled = false;
            $scope.isToggleOpen = false;

            // Producer will have option to dismiss this popup (“Don’t remind me again”), in which case they will not see it again
            // until campaign is at least halfway done, at which point they will keep seeing it until they dismiss for a second time.
            const calculateShowModal = (incentiveDuration, incentiveStartAt) => {
                const userLoggedin = sessionStorage.getItem('userLogin');
                const noShowModalSelected = localStorage.getItem('noShowModalSelected');
                const halfWay = Math.floor(incentiveDuration / 2);
                const passedHalfWay = moment().diff(incentiveStartAt, 'days') >= halfWay;
                if (!passedHalfWay) {
                    return !userLoggedin && !noShowModalSelected;
                } else {
                    return !userLoggedin && (noShowModalSelected !== '2');
                }
            };

            // Get results based on set filters
            $scope.getResults = function () {
                if (!$scope.fetchedOrganizations) { return; }
                if (!$scope.hasFetchedResults) {
                    $scope.hasFetchedResults = true;
                }

                $scope.isLoading = true;
                spinnerService.show('globalSpinner');

                const filterWithRating = _.merge($scope.filters,
                    { filterRating: $scope.isToggleOpen },
                    // currently - only the ho3 product is being supported for star-rating;
                    // therefore adding this filter here if requested.
                    { product: $scope.isToggleOpen ? 'ho3' : '*'}
                );
                const filterWithLimitAndOffset = _.assign({
                    offset: ($scope.currentPage - 1) * $scope.itemsPerPage,
                    limit: parseInt($scope.itemsPerPage, 10),
                    userOrg: UserService.getOrgId(),
                    userId: UserService.getId(),
                }, filterWithRating);

                const getResultsPromise = $scope.type === 'policies'
                    ? APIService.findPolicies(filterWithLimitAndOffset)
                    : APIService.findLeads(filterWithLimitAndOffset);

                getResultsPromise.then((results) => {
                    $scope.results = results[$scope.type].map(result => {
                        try {
                            const { street, city, state, zip } = result;
                            const isBuilder = _.get(result, 'data.organization_is_builder', false);
                            const organizationId = _.get(result, 'data.organization_id');
                            const community_name = _.get(result, 'data.builder_info.community_name');
                            const model_name = _.get(result, 'data.builder_info.model_name');

                            const { name: org_name } = _.find($scope.filterOrganizations, o => o.id === organizationId) || {};

                            const address = `${street}, ${city}, ${state.toUpperCase()} ${zip} ${isBuilder ? '(new build)' : ''}`;
                            return _.merge(result, { address, community_name, model_name, org_name });
                        } catch (err) {
                            $log.error(`Invalid: unable to process result ${result.id}`);
                            return result;
                        }
                    });
                    $scope.isLoading = false;
                    spinnerService.hide('globalSpinner');
                    $scope.totalItems = results.count;
                }).then(() => {
                    const incentiveQuery = '{ Incentive { reminderTitle reminderText startDate endDate status confirmationBannerLink } }';
                    IncentivesService.fetchIncentives(incentiveQuery).then((response) => {
                        const activeIncentive = response.data.data.Incentive.find((incentive) => incentive.status === 'ACTIVE');
                        const { endDate, startDate, reminderTitle, reminderText, confirmationBannerLink } = activeIncentive;
                        const incentiveDuration = moment(endDate).diff(startDate, 'days');
                        const showIncentiveModal = calculateShowModal(incentiveDuration, startDate);
                        if (!!activeIncentive && showIncentiveModal) {
                            sessionStorage.setItem('userLogin', '1');
                            $mdDialog
                                .show({
                                    parent: angular.element(document.body),
                                    template: `<incentives-modal title='title' text='text' link='link' no-remind-me='noRemindMe'></incentives-modal>`,
                                    controller: ($scope) => {
                                        $scope.title = reminderTitle;
                                        $scope.text = reminderText;
                                        $scope.noRemindMe = false;
                                        $scope.link = confirmationBannerLink;
                                    },
                                })
                                .then((value) => {
                                    if (value) {
                                        const currentValue = localStorage.getItem(
                                            'noShowModalSelected'
                                        );
                                        if (currentValue && currentValue === '1') {
                                            localStorage.setItem('noShowModalSelected', 2);
                                        } else {
                                            localStorage.setItem('noShowModalSelected', 1);
                                        }
                                    }
                                });
                        }
                    });
                });
            };

            $scope.policiesSearch = () => {
                $state.go('.', $scope.filters, { location: 'replace' }).then(() => {
                    $scope.getResults();
                });
            };

            $scope.clearKeyWord = () => {
                $scope.filters.keyword = '';
            };

            $scope.clearSearch = () => {
                $scope.filters = Object.assign({}, $scope.defaultFilter);
                $scope.searchBarFilled = false;
                $scope.getResults();
                $state.go('.', $scope.defaultFilter,{ location: 'replace' });
            };

            $scope.filterRating = () => {
                $scope.isToggleOpen = !$scope.isToggleOpen;
                $scope.getResults();
            };
            $scope.shouldUseSegmentation = false;
            FlagshipService.getCampaignData({ campaignID: FLAGSHIP_SEGMENTATION_CAMPAIGN_ID }).then((res) => {
                $scope.shouldUseSegmentation =
                    res.data &&
                    res.data.variation &&
                    res.data.variation.modifications &&
                    res.data.variation.modifications.value &&
                    res.data.variation.modifications.value.accessCustomerSegmentation;
                if($scope.shouldUseSegmentation) {
                    $rootScope.$broadcast('segmentation.turnOn');
                }
            });

            $scope.$watch('filters', (newValue) => {
                if (!_.isEqual(newValue, $scope.defaultFilter)) {
                    $scope.searchBarFilled = true;
                }
            }, true);

            // Get all organizations for current user
            APIService.findAvailableOrganizationsForUser().then(organizations => {
                $scope.filterOrganizations = organizations;
                $scope.fetchedOrganizations = true;
            });

            // Fetch policies on filter and page changes
            $scope.$watch('[currentPage, itemsPerPage, filterOrganizations]', $scope.getResults, true);
        }
    };
});
